<template>
  <div class="select-box" v-on:click="open=!open" :class="{open: open}">
    <input type="hidden" :name="name" :value="value" />
    <div class="active" v-if="value">{{ options[value] }}</div>
    <div class="active" v-else style="opacity: .5">{{ placeholder }}</div>
    <div class="" v-for="(optLabel, optVal) in options" v-on:click.stop="select(optVal)">{{ optLabel }}</div>
  </div>
</template>
<script>
export default {
  props: ["value", "placeholder", "options", "name"],
  data: ()=>({
    open: false,
  }),
  methods: {
    select(val) {
      this.open=false;
      this.$emit("input", val);
    },
  },
}
</script>
