var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "select-box",
      class: { open: _vm.open },
      on: {
        click: function($event) {
          _vm.open = !_vm.open
        }
      }
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: _vm.name },
        domProps: { value: _vm.value }
      }),
      _vm._v(" "),
      _vm.value
        ? _c("div", { staticClass: "active" }, [
            _vm._v(_vm._s(_vm.options[_vm.value]))
          ])
        : _c("div", { staticClass: "active", staticStyle: { opacity: ".5" } }, [
            _vm._v(_vm._s(_vm.placeholder))
          ]),
      _vm._v(" "),
      _vm._l(_vm.options, function(optLabel, optVal) {
        return _c(
          "div",
          {
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.select(optVal)
              }
            }
          },
          [_vm._v(_vm._s(optLabel))]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }