<template>
            <form method="post" v-on:submit="submit">
              <h3>Подаци компаније</h3>
              <div class="form-group row">
                <div class="col-md-12">
                  <input type="text" class="form-control" id="ime" aria-describedby="" placeholder="Пун назив организације или агенције" name="title" v-model="title" ref="title" :class="{error: submitted && !valid.title}">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <input type="text" class="form-control" id="ime" aria-describedby="" placeholder="Адреса" name="address" v-model="address" ref="address" :class="{error: submitted && !valid.address}">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6">
                  <input type="text" class="form-control" id="ime" aria-describedby="" placeholder="ПИБ" name="pib" v-model="pib" ref="pib" :class="{error: submitted && !valid.pib}">
                </div>
                <div class="col-md-6">
                  <input type="text" class="form-control" id="tel" aria-describedby="" placeholder="Матични број" name="mb" v-model="mb" ref="mb" :class="{error: submitted && !valid.mb}">
                </div>
              </div>
              <h3>Особа за контакт</h3>
              <div class="form-group row">
                <div class="col-md-12">
                  <input type="text" class="form-control" id="name" aria-describedby="" placeholder="Име и презиме" name="name" v-model="name" ref="name" :class="{error: submitted && !valid.name}">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6">
                  <input type="text" class="form-control" id="email" aria-describedby="" placeholder="Телефон" name="phone" v-model="phone" ref="phone" :class="{error: submitted && !valid.phone}">
                </div>
                <div class="col-md-6">
                  <input type="text" class="form-control" id="subject" aria-describedby="" placeholder="Мобилни телефон" name="mobile" v-model="mobile" ref="mobile" :class="{error: submitted && !valid.mobile}">
                </div>
              </div>
              <div class="form-note" v-html="text"></div>
              <h3>Списак учесника</h3>
              <template v-for="(person, pi) in people">
                <div class="form-group row">
                  <div class="col-md-12">
                    <input type="text" class="form-control" id="email" aria-describedby="" placeholder="Име и презиме" :name="'people['+pi+'][name]'" v-model="person.name" ref="personname" :class="{error: submitted && !valid.people[pi].name}">
                    <a class="remove" v-if="pi" v-on:click="remove(pi)">&times;</a>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-6">
                    <ext-multiselect placeholder="Пријава за спорт" :options="evt1" :name="'people['+pi+'][topics1]'" v-model="person.topics1"></ext-multiselect>
                  </div>
                  <div class="col-md-6">
                    <ext-multiselect placeholder="Пријава за излет" :options="evt2" :name="'people['+pi+'][topics2]'" v-model="person.topics2"></ext-multiselect>
                  </div>
                </div>
              </template>
              <div style="text-align: center">
                <a class="btn-like" v-on:click="addNew">ДОДАЈ НОВОГ УЧЕСНИКА!</a>
              </div>
              <br />
              <h3 v-if="false">Смештај</h3>
              <div v-if="false" class="form-group">
                  <ext-select placeholder="Једнокреветна" name="apt1" v-model="apt1" ref="apt1" :options="aptOptions" :class="{error: submitted && !valid.apt1}"></ext-select>
              </div>
              <div v-if="false" class="form-group">
                  <ext-select placeholder="Двокреветна" name="apt2" v-model="apt2" ref="apt2" :options="aptOptions" :class="{error: submitted && !valid.apt2}"></ext-select>
              </div>
              <div v-if="false" class="form-group">
                  <ext-select placeholder="Трокреветна" name="apt3" v-model="apt3" ref="apt3" :options="aptOptions" :class="{error: submitted && !valid.apt3}"></ext-select>
              </div>
              <button type="submit" class="btn btn-primary">Пошаљите</button>
            </form>
</template>

<script>
export default {
  props: ["text"],
  data: ()=>({
    title: "",
    address: "",
    pib: "",
    mb: "",
    name: "",
    phone: "",
    mobile: "",
    people: [{
      name: "",
      topics1: [],
      topics2: []
    }],
    apt1: null,
    apt2: null,
    apt3: null,
    evt1: [],
    evt2: [],
    aptOptions: {
      "0": "0",
      "1": "1", "2": "2", "3": "3",
      "4": "4", "5": "5", "6": "9",
      "7": "7", "8": "8", "9": "9"
    },
    personTemplate: null,
    submitted: false,
  }),
  computed: {
    valid() {
      return {
        title: !!this.title,
        address: !!this.address,
        pib: !!this.pib,
        mb: !!this.mb,
        name: !!this.name,
        phone: !!this.phone,
        mobile: !!this.mobile,
        people: this.people.map((a)=>({
          name: !!a.name,
        })),
        apt1: !!this.apt1,
        apt2: !!this.apt2,
        apt3: !!this.apt3,
      }
    },
  },
  mounted() {
    this.personTemplate=JSON.parse(JSON.stringify(this.people[0]));
    this.evt1=window.evt1 || [];
    this.evt2=window.evt2 || [];
  },
  methods: {
    submit($e) {
      this.submitted=true;
      var valid=true;
      for(var field in this.valid) {
        if (field=="people") {
          for(var pi in this.valid[field]) {
            if (!this.valid[field][pi].name) {
              if (valid) {
                this.$refs["personname"][pi].focus();
              }
              valid=false
            }
          }
        } else if (!this.valid[field]) {
          if (valid)
            this.$refs[field].focus();
          valid=false;
        }
      }
      if (!valid)
        $e.preventDefault();
    },
    addNew() {
      this.people.push(JSON.parse(JSON.stringify(this.personTemplate)));
    },
    remove(index) {
      this.people.splice(index, 1);
    }
  },
}
</script>
