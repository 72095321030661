import $ from 'jquery';
import bootstrap from 'bootstrap';
import Vue from 'vue';
import RegisterForm from './register-form.vue';
import ContactForm from './contact-form.vue';
import Select from './ext-select.vue';
import MultiSelect from './ext-multiselect.vue';


$(".ag-days:not(.gallery) a").css("cursor", "pointer").click(function(e) {
  e.preventDefault();
  var index=$(".ag-days a").index(this);
  $(".ag-box").hide().eq(index).show();
  $(".ag-days a").removeClass("active").eq(index).addClass("active");
});

Vue.component("register-form", RegisterForm);
Vue.component("contact-form", ContactForm);
Vue.component("ext-select", Select);
Vue.component("ext-multiselect", MultiSelect);

$("[vue-data]").each(function() {
  new Vue({
    el: this,
    data: JSON.parse($(this).attr("vue-data")),
  });
});

$(".mob-handle a").click(function(e) {
  e.preventDefault();
  $("header").toggleClass("open");
});

var galIndex=0;
var showGallery=function(items, index) {
  $(".gal-popup").css("display", "flex");
  $(".gal-popup .imgs").html("");
  for(var i=0;i<items.length;i++) {
    var div=$("<div />").appendTo($(".gal-popup .imgs")).hide();
    div.html(items.eq(i).html());
    div.find("a.img-link").remove();
  }
  $(".gal-popup .imgs>div").eq(index).show();
  $(".gal-popup .imgs a").removeAttr("href");
  galIndex=index;
  if (items.length<2)
    $(".gal-popup .nav").hide();
  else
    $(".gal-popup .nav").show();
}
$(".gal-popup .closex a").click(function(e) {
  e.preventDefault();
  $(".gal-popup .imgs").html("");
  $(".gal-popup").css("display", "none");
});

$(".gal-popup .next").click(function(e) {
  e.preventDefault();
  galIndex++;
  galIndex%=$(".gal-popup .imgs>div").length;
  $(".gal-popup .imgs>div").hide().eq(galIndex).show();
});
$(".gal-popup .prev").click(function(e) {
  e.preventDefault();
  galIndex+=$(".gal-popup .imgs>div").length-1;
  galIndex%=$(".gal-popup .imgs>div").length;
  $(".gal-popup .imgs>div").hide().eq(galIndex).show();
});

$(".gal-list:not(.archive) a").click(function(e) {
  e.preventDefault();
  var items=$(".gal-list .img-con");
  var index=$(".gal-list>div").index($(this).parent().parent());
  showGallery(items, index);
});


$("img.img-left").each(function() {
  $(this).removeClass("img-left");
  var container=$("<div />", {"class": "img left"});
  $(this).wrap(container);
  var title=$("<div />", {"class": "title", html: $(this).attr("alt")});
  $(this).parent().append(title);
});
$("img.img-right").each(function() {
  $(this).removeClass("img-right");
  var container=$("<div />", {"class": "img right"});
  $(this).wrap(container);
  var title=$("<div />", {"class": "title", html: $(this).attr("alt")});
  $(this).parent().append(title);
});

var scrollHandler=function() {
  var scrollTop=$(window).scrollTop();
  var windowHeight=$(window).innerHeight();
  $(".intro-image").each(function() {
    var offsetTop=$(this).offset().top;
    var offsetHeight=$(this).innerHeight();
    var minPos=Math.min(offsetTop-windowHeight, 0);
    var maxPos=offsetTop+offsetHeight;
    var pos=Math.min(1, Math.max(0, (scrollTop-minPos)/(maxPos-minPos)));
    $(this).find("img").css("transform", "scale("+(pos*0.2+1)+")");
  });
}

$(window).on("scroll", scrollHandler);
$(window).on("resize", scrollHandler);
scrollHandler();

$(".map").each(function() {
  var lat=$(this).attr("data-lat")*1;
  var lng=$(this).attr("data-lng")*1;
  var map = new google.maps.Map(this, {
    center: {lat: lat, lng: lng},
    zoom: $(this).attr("data-zoom")*1,
    streetViewControl: false, zoomControl: false, mapTypeControl: false
  });
  var marker=new google.maps.Marker({
    position: {lat: lat, lng: lng},
    map: map,
//     title: place[2]
  });
  
  
});
