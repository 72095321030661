var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { attrs: { method: "post" }, on: { submit: _vm.submit } }, [
    _c("div", { staticClass: "form-group row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name"
            }
          ],
          ref: "name",
          staticClass: "form-control",
          class: { error: _vm.submitted && !_vm.valid.name },
          attrs: {
            type: "text",
            id: "ime",
            "aria-describedby": "",
            placeholder: "Име и презиме",
            name: "name"
          },
          domProps: { value: _vm.name },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.name = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.phone,
              expression: "phone"
            }
          ],
          ref: "phone",
          staticClass: "form-control",
          class: { error: _vm.submitted && !_vm.valid.phone },
          attrs: {
            type: "text",
            id: "tel",
            "aria-describedby": "",
            placeholder: "Телефон",
            name: "phone"
          },
          domProps: { value: _vm.phone },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.phone = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.email,
              expression: "email"
            }
          ],
          ref: "email",
          staticClass: "form-control",
          class: { error: _vm.submitted && !_vm.valid.email },
          attrs: {
            type: "email",
            id: "email",
            "aria-describedby": "",
            placeholder: "Е-маил",
            name: "email"
          },
          domProps: { value: _vm.email },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.email = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.subject,
              expression: "subject"
            }
          ],
          ref: "subject",
          staticClass: "form-control",
          class: { error: _vm.submitted && !_vm.valid.subject },
          attrs: {
            type: "",
            id: "subject",
            "aria-describedby": "",
            placeholder: "Сврха поруке",
            name: "subject"
          },
          domProps: { value: _vm.subject },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.subject = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.message,
            expression: "message"
          }
        ],
        ref: "message",
        staticClass: "form-control",
        class: { error: _vm.submitted && !_vm.valid.message },
        attrs: {
          id: "message",
          rows: "3",
          placeholder: "Порука",
          name: "message"
        },
        domProps: { value: _vm.message },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.message = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "btn btn-primary", attrs: { type: "submit" } },
      [_vm._v("Пошаљите")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }