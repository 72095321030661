<template>
            <form method="post" v-on:submit="submit">
              <div class="form-group row">
                <div class="col-md-6">
                  <input type="text" class="form-control" id="ime" aria-describedby="" placeholder="Име и презиме" name="name" ref="name" v-model="name" :class="{error: submitted && !valid.name}">
                </div>
                <div class="col-md-6">
                  <input type="text" class="form-control" id="tel" aria-describedby="" placeholder="Телефон" name="phone" ref="phone" v-model="phone" :class="{error: submitted && !valid.phone}">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6">
                  <input type="email" class="form-control" id="email" aria-describedby="" placeholder="Е-маил" name="email" ref="email" v-model="email" :class="{error: submitted && !valid.email}">
                </div>
                <div class="col-md-6">
                  <input type="" class="form-control" id="subject" aria-describedby="" placeholder="Сврха поруке" name="subject" ref="subject" v-model="subject" :class="{error: submitted && !valid.subject}">
                </div>
              </div>
              <div class="form-group">
                <textarea class="form-control" id="message" rows="3" placeholder="Порука" name="message" ref="message" v-model="message" :class="{error: submitted && !valid.message}"></textarea>
              </div>
              <button type="submit" class="btn btn-primary">Пошаљите</button>
            </form>
</template>

<script>
export default {
  data: ()=>({
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
    submitted: false,
  }),
  computed: {
    valid() {
      return {
        name: !!this.name,
        phone: !!this.phone,
        email: !!this.email,
        subject: !!this.subject,
        message: !!this.message,
      };
    },
  },
  methods: {
    submit($e) {
      this.submitted=true;
      var valid=true;
      for(var field in this.valid) {
        if (!this.valid[field]) {
          if (valid)
            this.$refs[field].focus();
          valid=false;
        }
      }
      if (!valid)
        $e.preventDefault();
    },
  },
}
</script>
