var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { attrs: { method: "post" }, on: { submit: _vm.submit } },
    [
      _c("h3", [_vm._v("Подаци компаније")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.title,
                expression: "title"
              }
            ],
            ref: "title",
            staticClass: "form-control",
            class: { error: _vm.submitted && !_vm.valid.title },
            attrs: {
              type: "text",
              id: "ime",
              "aria-describedby": "",
              placeholder: "Пун назив организације или агенције",
              name: "title"
            },
            domProps: { value: _vm.title },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.title = $event.target.value
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.address,
                expression: "address"
              }
            ],
            ref: "address",
            staticClass: "form-control",
            class: { error: _vm.submitted && !_vm.valid.address },
            attrs: {
              type: "text",
              id: "ime",
              "aria-describedby": "",
              placeholder: "Адреса",
              name: "address"
            },
            domProps: { value: _vm.address },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.address = $event.target.value
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.pib,
                expression: "pib"
              }
            ],
            ref: "pib",
            staticClass: "form-control",
            class: { error: _vm.submitted && !_vm.valid.pib },
            attrs: {
              type: "text",
              id: "ime",
              "aria-describedby": "",
              placeholder: "ПИБ",
              name: "pib"
            },
            domProps: { value: _vm.pib },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.pib = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.mb,
                expression: "mb"
              }
            ],
            ref: "mb",
            staticClass: "form-control",
            class: { error: _vm.submitted && !_vm.valid.mb },
            attrs: {
              type: "text",
              id: "tel",
              "aria-describedby": "",
              placeholder: "Матични број",
              name: "mb"
            },
            domProps: { value: _vm.mb },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.mb = $event.target.value
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("h3", [_vm._v("Особа за контакт")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name"
              }
            ],
            ref: "name",
            staticClass: "form-control",
            class: { error: _vm.submitted && !_vm.valid.name },
            attrs: {
              type: "text",
              id: "name",
              "aria-describedby": "",
              placeholder: "Име и презиме",
              name: "name"
            },
            domProps: { value: _vm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.phone,
                expression: "phone"
              }
            ],
            ref: "phone",
            staticClass: "form-control",
            class: { error: _vm.submitted && !_vm.valid.phone },
            attrs: {
              type: "text",
              id: "email",
              "aria-describedby": "",
              placeholder: "Телефон",
              name: "phone"
            },
            domProps: { value: _vm.phone },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.phone = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.mobile,
                expression: "mobile"
              }
            ],
            ref: "mobile",
            staticClass: "form-control",
            class: { error: _vm.submitted && !_vm.valid.mobile },
            attrs: {
              type: "text",
              id: "subject",
              "aria-describedby": "",
              placeholder: "Мобилни телефон",
              name: "mobile"
            },
            domProps: { value: _vm.mobile },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.mobile = $event.target.value
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "form-note",
        domProps: { innerHTML: _vm._s(_vm.text) }
      }),
      _vm._v(" "),
      _c("h3", [_vm._v("Списак учесника")]),
      _vm._v(" "),
      _vm._l(_vm.people, function(person, pi) {
        return [
          _c("div", { staticClass: "form-group row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: person.name,
                    expression: "person.name"
                  }
                ],
                ref: "personname",
                refInFor: true,
                staticClass: "form-control",
                class: { error: _vm.submitted && !_vm.valid.people[pi].name },
                attrs: {
                  type: "text",
                  id: "email",
                  "aria-describedby": "",
                  placeholder: "Име и презиме",
                  name: "people[" + pi + "][name]"
                },
                domProps: { value: person.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(person, "name", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              pi
                ? _c(
                    "a",
                    {
                      staticClass: "remove",
                      on: {
                        click: function($event) {
                          return _vm.remove(pi)
                        }
                      }
                    },
                    [_vm._v("×")]
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("ext-multiselect", {
                  attrs: {
                    placeholder: "Пријава за спорт",
                    options: _vm.evt1,
                    name: "people[" + pi + "][topics1]"
                  },
                  model: {
                    value: person.topics1,
                    callback: function($$v) {
                      _vm.$set(person, "topics1", $$v)
                    },
                    expression: "person.topics1"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("ext-multiselect", {
                  attrs: {
                    placeholder: "Пријава за излет",
                    options: _vm.evt2,
                    name: "people[" + pi + "][topics2]"
                  },
                  model: {
                    value: person.topics2,
                    callback: function($$v) {
                      _vm.$set(person, "topics2", $$v)
                    },
                    expression: "person.topics2"
                  }
                })
              ],
              1
            )
          ])
        ]
      }),
      _vm._v(" "),
      _c("div", { staticStyle: { "text-align": "center" } }, [
        _c("a", { staticClass: "btn-like", on: { click: _vm.addNew } }, [
          _vm._v("ДОДАЈ НОВОГ УЧЕСНИКА!")
        ])
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      false ? _c("h3", [_vm._v("Смештај")]) : _vm._e(),
      _vm._v(" "),
      false
        ? _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("ext-select", {
                ref: "apt1",
                class: { error: _vm.submitted && !_vm.valid.apt1 },
                attrs: {
                  placeholder: "Једнокреветна",
                  name: "apt1",
                  options: _vm.aptOptions
                },
                model: {
                  value: _vm.apt1,
                  callback: function($$v) {
                    _vm.apt1 = $$v
                  },
                  expression: "apt1"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      false
        ? _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("ext-select", {
                ref: "apt2",
                class: { error: _vm.submitted && !_vm.valid.apt2 },
                attrs: {
                  placeholder: "Двокреветна",
                  name: "apt2",
                  options: _vm.aptOptions
                },
                model: {
                  value: _vm.apt2,
                  callback: function($$v) {
                    _vm.apt2 = $$v
                  },
                  expression: "apt2"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      false
        ? _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("ext-select", {
                ref: "apt3",
                class: { error: _vm.submitted && !_vm.valid.apt3 },
                attrs: {
                  placeholder: "Трокреветна",
                  name: "apt3",
                  options: _vm.aptOptions
                },
                model: {
                  value: _vm.apt3,
                  callback: function($$v) {
                    _vm.apt3 = $$v
                  },
                  expression: "apt3"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Пошаљите")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }