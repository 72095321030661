<template>
  <div class="select-box" v-on:click="open=!open" :class="{open: open}">
    <input type="hidden" :name="name+'[]'" :value="val" v-for="val in value" />
    <div class="active" v-if="value && value.length">{{ shownValue }}</div>
    <div class="active" v-else style="opacity: .5">{{ placeholder }}</div>
    <div class="" v-for="(optLabel, optVal) in options" v-on:click.stop="select(optVal)"><input type="checkbox" :value="optVal" v-model="value" /> {{ optLabel }}</div>
  </div>
</template>
<script>
export default {
  props: ["value", "placeholder", "options", "name"],
  computed: {
    shownValue() {
      var result=[];
      for(var opt of this.value || [])
        result.push(this.options[opt]);
      return result.join(", ");
    }
  },
  methods: {
    select(val) {
      var newVal=(this.value || []).slice();
      var found=newVal.indexOf(val);
      if (found==-1)
        newVal.push(val);
      else
        newVal.splice(found, 1);
      this.$emit("input", newVal);
    },
  },
  data: ()=>({
    open: false,
  }),
}
</script>
