var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "select-box",
      class: { open: _vm.open },
      on: {
        click: function($event) {
          _vm.open = !_vm.open
        }
      }
    },
    [
      _vm._l(_vm.value, function(val) {
        return _c("input", {
          attrs: { type: "hidden", name: _vm.name + "[]" },
          domProps: { value: val }
        })
      }),
      _vm._v(" "),
      _vm.value && _vm.value.length
        ? _c("div", { staticClass: "active" }, [_vm._v(_vm._s(_vm.shownValue))])
        : _c("div", { staticClass: "active", staticStyle: { opacity: ".5" } }, [
            _vm._v(_vm._s(_vm.placeholder))
          ]),
      _vm._v(" "),
      _vm._l(_vm.options, function(optLabel, optVal) {
        return _c(
          "div",
          {
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.select(optVal)
              }
            }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value,
                  expression: "value"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                value: optVal,
                checked: Array.isArray(_vm.value)
                  ? _vm._i(_vm.value, optVal) > -1
                  : _vm.value
              },
              on: {
                change: function($event) {
                  var $$a = _vm.value,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = optVal,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.value = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.value = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.value = $$c
                  }
                }
              }
            }),
            _vm._v(" " + _vm._s(optLabel))
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }